// BY John Makridis

var currentImageIndex = -1;
var imageURLs = new Array();
var fadeSpeed;
var elementID;
var sizeMode;
var sliderIntervalID;
var stop

var SCALING_MODE_NONE = 0;      // Uses the original image size
var SCALING_MODE_STRETCH = 1;   // Sets 'background-size' to '100% 100%'. This stretches the background image to fill the container, discarding the images aspect ratio.
var SCALING_MODE_COVER = 2;     // Sets 'background-size' to 'cover'. This makes the background images fill the entire container while retaining its aspect ratio.
var SCALING_MODE_CONTAIN = 3;   // Sets 'background-size' to 'contain'. This scales the bakcground image to the largest size such that both its width and its height can fit inside the content area



$.fn.tourmieBlackgroundSlideshow = function (options) {

    var settings = $.extend({
        imageURLs: [],
        duration: 5000,
        fadeSpeed: 1000,
        backgroundSize: 2,
        stop: false
    }, options);

    elementID = this.attr('id');
    fadeSpeed = settings.fadeSpeed;
    imageURLs = new Array();

    if (settings.stop)
        return clearInterval(sliderIntervalID)

    switch (settings.backgroundSize) {
        default:
            sizeMode = settings.backgroundSize;
            break;
        case SCALING_MODE_NONE:
            sizeMode = 'auto';
            break;
        case SCALING_MODE_STRETCH:
            sizeMode = '100% 100%';
            break;
        case SCALING_MODE_COVER:
            sizeMode = 'cover';
            break;
        case SCALING_MODE_CONTAIN:
            sizeMode = 'contain';
            break;
    }

    for (var i = 0; i < settings.imageURLs.length; i++) {
        var id = 'bgImage' + i;
        var src = settings.imageURLs[i];

        imageURLs.push(src);
    }

    currentImageIndex++;
    showImage(imageURLs[0]);


    sliderIntervalID = setInterval(showNextImage, settings.duration);

}


function showNextImage() {

    currentImageIndex++;

    if (currentImageIndex >= imageURLs.length) {
        currentImageIndex = 0;
    }

    showImage(imageURLs[currentImageIndex]);

}


function showImage(url) {

    $('#' + elementID).css({
        'background-image': "url('" + url + "')",
        'background-repeat': 'no-repeat',
        'background-position': 'center center',
        'background-size': sizeMode,
        '-moz-background-size': sizeMode,
        '-webkit-background-size': sizeMode
    });


    // UNCOMMENT THIS FOR TRANSITION EFFECT
    // if (currentImageIndex > 0)
    //     $('#' + elementID).css({
    //         '-webkit-transition': `background-image ${fadeSpeed}ms linear`,
    //         '-moz-transition': `background-image ${fadeSpeed}ms linear`,
    //         '-ms-transition': `background-image ${fadeSpeed}ms linear`,
    //         '-o-transition': `background-image ${fadeSpeed}ms linear`,
    //         'transition': `background-image ${fadeSpeed}ms linear`,
    //     })



}

